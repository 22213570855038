import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Col, Container, Row } from "react-bootstrap";
import "./Dashboard.scss";
import Notes from "./notes/Notes";
import Groups from "./groups/Groups";
import Projects from "./projects/Projects";
import AuthenticationContext from "contexts/AuthenticationContext";
import useFetch from "custom-hooks/useFetch";
import { CurrentUserState } from "types";

import "intro.js/introjs.css";
import { useDispatch, useSelector } from "react-redux";
import introJs from "intro.js";
import { TourStep } from "intro.js/src/packages/tour/steps";
import sendRequest from "services/dataService";
import config from "config";
import { loaduser } from "redux/userReducer";
import { loadDashboard } from "redux/dashboardReducer";
import AlertMessage from 'components/alert-message/AlertMessage';


const Dashboard = () => {
  const authenticationContext = React.useContext(AuthenticationContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const [fetchDashboardDataState, fetchDashboardData] = useFetch();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [closedGroupName, setClosedGroupName] = useState('');
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );
  
  const dashboardState = useSelector((state: { dashboard: any }) => state.dashboard);
  const { data: dashboardData, loading: isLoading, error: isFailed } = dashboardState;

  async function turnOffShowDashboardIntro() {
    const url = `${config.BASE_API_URL}api/v1.0/user/current/`;
    const response = await sendRequest({
      url,
      method: "PATCH",
      token: authenticationContext.token || "",
      body: JSON.stringify({
        show_dashboard_intro: false,
      }),
    });
    dispatch(loaduser(authenticationContext.token));
  }

  function startIntro() {
    var intro = introJs();
    intro.setOptions({
      highlightClass: "introjs-highlight",
      steps: steps,
      nextLabel: "Next →",
      prevLabel: "← Back",
    });
    intro.onExit(function () {
      if (userState.data.show_dashboard_intro) {
        turnOffShowDashboardIntro();
      }
    });
    intro.start();
    return intro;
  }

  useEffect(() => {
    if (userState.data.show_dashboard_intro) {
      startIntro();
    }
  }, [userState.data]);

  if (isFailed) {
    history.push("/login");
  }

  const steps: Partial<TourStep>[] = [
    {
      step: 1,
      title: "Dashboard",
      intro:
        "This page is your dashboard. " +
        "It’s your home base for adding documents and submitting them to groups. " +
        "It also provides a snapshot of pending critiques in your groups.",
    },
    {
      title: "Profile",
      element: "#user-dropdown",
      intro:
        "Set up your profile here so that your group members or " +
        "potential group members can get to know you better.",
      position: "bottom",
    },
    {
      title: "Groups",
      element: "#groups_title",
      intro:
        "This is your Groups area. " +
        "It’s where you will keep track of your submissions and those of your group. " +
        "Critique submissions by clicking the document name. " +
        "Or, go to the Group’s page to critique submissions. " +
        "This area only displays uncompleted critiques. " +
        "If you’ve completed a critique, you will still be able to view the work from your Group’s page.",
      position: "bottom",
    },
    {
      title: "Notes",
      element: "#notes-title",
      intro:
        "The notes area is for your quotes, " +
        "reminder notes, personal goals, or anything you’d like to keep handy on your dashboard.",
      position: "right",
    },
    {
      title: "Projects",
      element: "#projects_title",
      intro:
        "This is your projects area. " +
        "Documents housed here are private unless you submit them to your group. " +
        "Create a project to organize your drafts and chapters for a manuscript.  " +
        "To submit a document, you must first create a project and add a doc to it. " +
        "Then you can submit it.",
      position: "bottom",
    },
    {
      title: "New Project",
      element: "#add-project-button",
      intro:
        "Create a new project folder here. " +
        "It could be as broad as “Poems” or as narrow as “A New Beginning (Novel)”. " +
        "Next, you will add documents to a project. " +
        "Documents can be submitted for critique. ",
      position: "left",
    },
    {
      title: "How to add a new document",
      element: "#add-document-button",
      intro:
        "First, title your document. " +
        "Then, you may upload a.docx file or copy/paste your work into the box. " +
        "Tip: use paste & match style in your browser to keep your formatting for copy/paste. ",
    },
    {
      title: "Archive",
      element: "#archive-tab",
      intro:
        "This space houses projects that you’ve completed or that are no longer active. " +
        "You can always delete a project, but you can also just hide it from view and find it here.",
      position: "bottom",
    },
  ];

  useEffect(() => {
    const groupClosed = localStorage.getItem('groupClosed');
    const groupName = localStorage.getItem('closedGroupName');
    if (groupClosed === 'true' && groupName) {
      setShowConfirmation(true);
      setClosedGroupName(groupName);
      localStorage.removeItem('groupClosed');
      localStorage.removeItem('closedGroupName');
    }
  }, []);

  useEffect(() => {
    if (!dashboardData) {
      dispatch(loadDashboard(authenticationContext.token));
    }
  }, [dashboardData, dispatch, authenticationContext.token]);
  return (
    <Container className="dashboard">
      <Row>
        {showConfirmation && (
          <AlertMessage
            message={`The group "${closedGroupName}" has been closed successfully.`}
            variant="success"
            onClose={() => setShowConfirmation(false)}
          />
        )}
        <Col sm={3} md={3}>
          <Notes notes={dashboardData?.notes || []} />
        </Col>
        <Col sm={9} md={9}>
          <Groups
            invitations={dashboardData?.invitations}
            loading={fetchDashboardDataState.isLoading}
            replayTour={startIntro}
          />
          <br></br>
          <Projects />
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
